window.counter = function (targetCount) {
    return {
        count: 0,
        targetCount: targetCount,
        duration: 4000, // duration in milliseconds
        step: Math.max(1, Math.ceil(targetCount / (2000 / 10))), // calculate step size
        init() {
            this.$watch('count', (val) => {
                if (val >= this.targetCount) {
                    this.count = this.targetCount; // ensure count does not exceed target
                    clearInterval(this.interval);
                }
            });

            window.addEventListener('scroll', () => {
                const rect = this.$el.getBoundingClientRect();
                const isInView = (rect.top <= window.innerHeight - 200) && ((rect.top + rect.height) >= 0);
                if (isInView) {
                    this.startCounter();
                    // Remove the event listener if you want the counter to start only once
                    window.removeEventListener('scroll', this.startCounter);
                }
            });

            // this.observer = new IntersectionObserver((entries) => {
            //     if (entries.some(entry => entry.isIntersecting)) {
            //         console.log('IntersectionObserver entry:', entries.find(entry => entry.isIntersecting));
            //         this.startCounter();
            //         this.observer.disconnect();
            //     }
            // });

            // this.observer.observe(this.$el);
        },
        startCounter() {
            this.interval = setInterval(() => {
                if (this.count < this.targetCount) {
                    this.count += this.step;
                }
            }, 10); // update every 10ms for smoother animation
        },
        interval: null,
    }
}