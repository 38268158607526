import './countdown.js';

window.countdownTimer = function () {
    return {

        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        targetDate: '',


        startCountdown(targetDateString) {
            this.targetDate = new Date(targetDateString);

            const currentDate = new Date();
            const timeDifference = this.targetDate - currentDate;

            let self = this;

            if (timeDifference > 0) {

                window.countdown(
                    this.targetDate,
                    function (ts) {
                        self.months = ts.months;
                        self.days = ts.days;
                        self.hours = ts.hours;
                        self.minutes = ts.minutes;
                        self.seconds = ts.seconds;
                    },
                    countdown.MONTHS | countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS);

            } else {
                // Countdown completed
                this.months = this.days = this.hours = this.minutes = this.seconds = 0;
            }
        }
    };
}

